class TimeDown {
  constructor(textId, btnId) {
    this.textId = textId;
    this.btnId = btnId;
    this.text = document.getElementById(textId);
    this.btn = document.getElementById(btnId);
    this.timeId = null;
    this.time = 60;
  }

  start() {
    if (!this.text || !this.btn) {
      return;
    }
    this.stop();
    this.timeId = setInterval(() => {
      if (this.time <= 0) {
        this.setTimeHtml("");
        this.enabledBtn();
        this.stop();
        return;
      } else {
        this.setTimeHtml(this.time);
        this.disabledBtn();
      }
      this.time -= 1;
    }, 1000);
  }

  stop() {
    if (this.timeId) {
      clearInterval(this.timeId);
      this.timeId = null;
    }
  }

  disabledBtn() {
    if (this.btn) {
      this.btn.disabled = true;
    }
  }

  enabledBtn() {
    if (this.btn) {
      this.btn.disabled = false;
    }
  }

  setTimeHtml(text) {
    if (this.text) {
      const str = !text ? "" : "(" + text + ")";
      this.text.innerText = str;
    }
  }
}

export default TimeDown;
