import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["body"];
  timeId = null;

  // initialize() {

  // }

  // connect() {
  //   console.log("about Ctrl connnected !!!");
  // }

  // disconnect() {
  //   console.log("about Ctrl stoped ...");
  // }

  show() {
    this.clearTime();
    if (this.hasBodyTarget) {
      this.bodyTarget.classList.add("open");
    }
  }

  hide() {
    if (this.hasBodyTarget) {
      this.bodyTarget.classList.add("fade-out");
      this.timeId = setTimeout(() => {
        this.bodyTarget.classList.remove("open", "fade-out");
      }, 400);
    }
  }

  clearTime() {
    if (this.timeId) {
      clearTimeout(this.timeId);
      this.timeId = null;
    }
  }
}
