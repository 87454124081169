import Rails from "@rails/ujs";
import { strReplace } from "./string";

/**
 * 字符组输入
 * @param {String} inputId 隐藏输入框的 dom id，可用于提交表单数据
 * @param {String} formId  表单的 dom id，可用获取表单dom， 提交表单
 * @param {String} chaClass 字符组的输入框 class，用于字符的输入, 默认 "js-input-cha"
 * @example
  new InputCha("some input id");
  new InputCha("some input id", "some form id");
  new InputCha("some input id", "some form id", "some class name");
 */
class InputCha {
  constructor(inputId, formId = "CodeForm", chaClass = "js-input-cha") {
    this.inputId = inputId;
    this.formId = formId;
    this.chaClass = chaClass;
    this.inputDom = document.getElementById(inputId);
    this.formDom = document.getElementById(formId);
    this.chaDomArr = document.getElementsByClassName(chaClass);
    this.maxPosition = Math.max(this.chaDomArr.length - 1, 0);

    this.init();
  }

  init() {
    this.bindInput();
  }

  bindInput() {
    const chaArr = this.chaDomArr;
    for (let index = 0; index < chaArr.length; index++) {
      const inputCha = chaArr[index];
      inputCha.oninput = (evt) => {
        this.chaInputChange(evt);
      };
    }
  }

  chaInputChange(evt) {
    const dom = evt.target;
    const valStr = dom.value || "";
    const val = parseInt(valStr);
    const position = Number(dom.getAttribute("data-position"));

    if (valStr.match("-")) {
      dom.value = "";
      return;
    }
    if (val >= 0 && val <= 9) {
      this.chaReplace(valStr, position);
      if (position < this.maxPosition) {
        this.chaNext(position + 1);
      }
      this.submit();
    } else {
      dom.value = "";
    }
  }

  chaNext(position) {
    const chaArr = this.chaDomArr;
    for (let index = 0; index < chaArr.length; index++) {
      const inputCha = chaArr[index];
      const chaPosition = Number(inputCha.getAttribute("data-position"));
      if (position == chaPosition) {
        inputCha.focus();
      }
    }
  }

  chaReplace(val, position) {
    if (this.inputDom) {
      const valStr = this.inputDom.value;
      const valNewStr = strReplace(valStr, val, position);
      this.inputDom.value = valNewStr;
    }
  }

  submit() {
    const val = this.inputDom.value || "";
    if (!val.match(/\*/g)) {
      this.formDom && Rails.fire(this.formDom, "submit");
      this.formDom && this.formDom.submit();
    }
  }
}

export default InputCha;
