/**
 * 字符串重复
 * @example
  strRepeat('a', 3) // => "aaa";
  strRepeat('abc', 3) // => "abcabcabc";
 */
export function strRepeat(str, count) {
  let result = "";
  for (let index = 0; index < count; index++) {
    result += str;
  }
  return result;
}

/**
 * 字符串单个替换
 * @param {String} str 原始字符串
 * @param {String} newStr 目标字符串
 * @param {String|Number} start 开始位置
 * @param {Number} len 替换个数, 默认1
 *
 * @example
  var str = "abcdef";
  var s = "z";
  strReplace(str, s, 0)     // => "zbcdef";
  strReplace(str, s, 2)     // => "abzdef";
  strReplace(str, s, 2, 3)  // => "abzzzf";
  strReplace(str, s, 2, 4)  // => "abzzzz";
  strReplace(str, s, 2, 10) // => "abzzzz";
 */
export function strReplace(str, newStr, start, len = 1) {
  const startReal = Math.min(Math.max(start, 0), str.length);
  const lenReal = Math.min(len, str.length - startReal);
  const newStrReal = newStr[0];
  const newStrTarget = strRepeat(newStrReal, lenReal);

  const strStart = str.substr(0, startReal);
  const strEnd = str.substr(startReal + lenReal);

  return strStart + newStrTarget + strEnd;
}
