import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["navDown", "userDown"];

  // initialize() {

  // }

  // connect() {
  //   console.log("about Ctrl connnected !!!");
  // }

  // disconnect() {
  //   console.log("about Ctrl stoped ...");
  // }

  back() {
    history && history.back();
  }

  toggleShow() {
    if (this.hasNavDownTarget) {
      this.navDownTarget.classList.toggle("open");
    }
  }

  // toggleUserDown() {
  //   if (this.hasUserDownTarget) {
  //     this.userDownTarget.classList.toggle("open");
  //   }
  // }

}
