import InputCha from "./input_cha";
import TimeDown from "./time_down";
import Validation from "./validation";

const initFunctions = () => {
  // 字符组输入
  new InputCha("CodeInputCha", "CodeForm", "js-input-cha");
  // 倒计时
  const timeDown = new TimeDown("TimedownText", "TimedownBtn");
  timeDown.start();

  // 验证表单：注册和找回密码时的手机号
  window.mobile_regexp &&
    new Validation("MobileForm", {
      rules: {
        MobileNumber: {
          required: true,
          regex: mobile_regexp,
          pre: "MobileCountryCode",
        },
      },
    });
};

export default initFunctions;
