import Rails from "@rails/ujs";

/**
 * 表单验证
 * @example
  "MobileForm" // 表单ID
  "MobileNumber" // 表单自动ID
  new Validation("MobileForm", {
    rules: {
      MobileNumber: {
        required: true,
        regex: china_mobile_regexp,
      },
    },
  });
 */
class Validation {
  constructor(formID, opts = defaultOpts) {
    this.formID = formID;
    this.formDom = document.getElementById(formID);
    this.options = Object.assign({}, opts);

    this.init();
  }

  init() {
    this.bindSubmit();
    this.bindInput();
  }

  bindSubmit() {
    if (this.formDom) {
      this.formDom.onsubmit = (evt) => {
        let result = true;
        const rules = this.options.rules || {};
        for (const fieldId in rules) {
          if (rules.hasOwnProperty(fieldId)) {
            const rule = rules[fieldId];
            const field = document.getElementById(fieldId);
            const errorDom = document.getElementById("Feed" + fieldId);
            result = result && this.checkField(field, errorDom, rule);
          }
        }
        if (!result) {
          setTimeout(() => {
            Rails.enableElement(this.formDom.commit);
          }, 100);
        }
        return result;
      };
    }
  }

  bindInput() {
    const rules = this.options.rules || {};
    for (const fieldId in rules) {
      if (rules.hasOwnProperty(fieldId)) {
        const rule = rules[fieldId];
        const field = document.getElementById(fieldId);
        const errorDom = document.getElementById("Feed" + fieldId);
        if (field) {
          field.oninput = (evt) => {
            this.checkField(field, errorDom, rule);
          };
        }
      }
    }
  }

  checkField(field, errorDom, rule) {
    const value = field.value;
    if (rule.required) {
      if (!value) {
        errorDom.innerText = errorDom.getAttribute("data-required");
        errorDom.classList.add("invalid");
        return false;
      }
    }
    const preDom = document.getElementById(rule.pre);
    // console.log("=== pre dom value ===>", preDom.value);
    if (rule.regex) {
      let regex = null;
      if (typeof rule.regex === "string") {
        regex = new RegExp(rule.regex);
      }
      if (typeof rule.regex === "object" && preDom) {
        const str = rule.regex[preDom.value];
        regex = str ? new RegExp(str) : null;
      }
      if (regex && !regex.test(value)) {
        errorDom.innerText = errorDom.getAttribute("data-regex");
        errorDom.classList.add("invalid");
        return false;
      }
    }

    errorDom.innerText = "";
    errorDom.classList.remove("invalid");
    return true;
  }
}

const defaultOpts = {
  rules: {},
};

export default Validation;
