import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable"];

  // initialize() {

  // }

  // connect() {
  //   console.log("about Ctrl connnected !!!");
  // }

  // disconnect() {
  //   console.log("about Ctrl stoped ...");
  // }

  toggleShow() {
    
    if (this.hasToggleableTarget) {
      console.log('this.toggleableTarget.style.display',this.toggleableTarget.style.display)
      if(this.toggleableTarget.style.display == 'block') {
        this.toggleableTarget.style.display = 'none';
      }
      else {
        this.toggleableTarget.style.display = 'block';
      }
    }
  }

}
